$(function() {
  // スクロールで背景微移動
  function scrollBg(config) {
    scrollPos = $(this).scrollTop();
    config.bg.css({
      'background-position': 'center ' + (-scrollPos / 14) + "px"
    });
  }
  $(window).scroll(function() {
    scrollBg({
      bg: $('body')
    });
  });
  // headlineのmarginTop設定
  $(window).on('load resize', function() {
    if ($(window).width() > 768) {
      var headbarHeight = $('#headbar').height();
      var headerHeight = $('.navbar').height() + headbarHeight + 16;
      $('.navbar').css('top', headbarHeight + 'px');
      $('#nav-lang').css('top', headbarHeight + 20 + 'px');
      $('#feature').css('marginTop', 0);
    } else {
      var headerHeight = $('.navbar').height() + 60 + 14;
      $('.navbar').css('top', '60px');
      $('#nav-lang').css('top', 0);
      $('#feature').css('marginTop', headerHeight + 'px');
    }
    // alert(headerHeight);
    $('.headline').css('marginTop', headerHeight + 'px');
  });
  //toTopの表示・非表示
  function displayToTop() {
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $(".to-page-top").fadeOut();
    } else {
      $(".to-page-top").fadeIn();
      $(".to-page-top a").smoothScroll({
        offset: 0
      });
    }
  }
  $(window).on("load scroll", displayToTop);
  // スマホ時 headbar マーキー表示
  $('#headbar .marquee').est({
    roopTiming: 0,
    slideSpeed: 1500,
    slideDelay: 0,
    remainderSpeed: 1500,
    remainderDelay: 0,
    outSpeed: 1500
  });

  // home
  if ($('body').hasClass('home')) {
    // feature全画面背景
    // $('#feature').tubular({videoId: 'eB0uouBs0S4'}); // YoutubeのID
    // if ($(window).width() > 768) {
    $('#feature-pc').tubular({
      videoId: 'eB0uouBs0S4' // YoutubeのID
    });
    // }
    // if ($(window).width() < 768) {
    $("#feature-sp").slick({
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 3000,
      infinite: true,
      arrows: false,
      dots: false,
      fade: true,
      pauseOnHover: false
    });
    // }
    // programスライドショー
    $('.program-pickup').slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: false
    });
  }

  // faq
  if ($('body').hasClass('faq')) {
    $("#faq-toggle dt").on("click", function() {
      $(this).next().slideToggle();
    });
  }

  // stay
  if ($('body').hasClass('stay')) {
    var $setElm = $('.yado-info p a');
    // $setElm.each(function(){
    //        //30文字以上になったら
    //     if ($(this).text().length > 33) {
    //        //27文字まで表示する、という設定
    //         $(this).text($(this).text().substr(0, 30));
    //         //27文字以降に表示する代替テキスト。「...」とかが一般的？
    //         $(this).append('...');
    //     }
    //
    // });
  }
});
